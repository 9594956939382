<template>
  <div id="app">
    <!-- Loader -->
    <!-- <div id="preloader" v-if="loading">
      <div id="status">
        <div class="spinner"></div>
      </div>
    </div> -->
    <!-- <div class="loader d-flex align-items-center" v-if="loading">
      <div class="spinner1" >
        <div class="spinner1 spinner2">
          <div class="spinner1 spinner3"></div>
        </div>   
      </div>
    </div> -->
    <div v-if="loading" class="loading-back">
      <img src="../src/assets/images/loading.gif" alt="">
    </div>
    <!-- <video playsinline autoplay muted loop>
      <source src="../public/loading.mp4" type="video/webm">
  </video> -->
    
    <router-view v-if="!loading" />
  </div>
</template>

<script>
import WebNotifyService from './services/webnotify.service'
export default {
  data() {
    return {
      loading: false
    };
  },
  created() {
    setTimeout(() => (this.loading = false), 4000);
  },
  watch:{
    $route(){
      this.loading = false
      setTimeout(() => (this.loading = false),4000);
      if(!!localStorage.getItem('user_info')){
        WebNotifyService.GetUnReadMessagesCount().then(res => {
            this.$store.state.messageCount = res.data.result
        })
      }
      
    }
  }
};
</script>
<style lang="scss">
.loader{
  width: 100%;
  height: 100vh !important;
  background-color: transparent;
  // background: linear-gradient(to right top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3));
}
.spinner1 {
  margin: auto;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #5168A0;
  border-bottom: none;
  border-left: none;
  animation: spin 4.5s infinite linear reverse;
  
    .spinner2 {
    margin: 25px auto;
    height: 200px;
    width: 200px;
    animation: spin 2s infinite linear;
    }
  
    .spinner3 {
    margin: 25px auto;
    height: 150px;
    width: 150px;
    animation: spin 1s infinite linear reverse;
    }
}

 @keyframes spin{
 
  100% {
    transform: rotate(360deg);
   }
}

.loading-back {
  background-color: transparent;
}
</style>